import React, { Component } from 'react';
import { connect } from 'react-redux';

export class LoadMoreBtn extends Component {
  state = {
    page: 1,
    prevData: 0,
    loadMore: true,
    showLess: false,
  };


  loadMoreBtn = () => {
    const { page, prevData } = this.state;
    const { count, defCount, } = this.props;

    if (count !== prevData) {
      const newPage = page + 1;
      this.setState({ prevData: count, page: newPage });
      this.props.onLoadData(newPage);
    } else {
      if (count === defCount) {
        this.setState({ loadMore: false });
      } else {
        this.setState({ showLess: true });
      }
    }
  };


  showLessBtn = () => {
    this.setState({
      page: 1,
      prevData: 0,
      loadMore: true,
      showLess: false,
    });
    this.props.onLoadData(1);
  };

  render() {
    const { count, defCount = 8, contentData } = this.props;
    const { loadMore, showLess } = this.state;

    return (
      <div className='flex justify-center items-center gap-[20px] mt-[20px]'>
        {count % defCount === 0 && loadMore && !showLess ? (
          <button
            onClick={this.loadMoreBtn}
            className=' p-[7px_15px] rounded-[50px] font-[700] text-[16px] text-[#fff] bg-[#F05127]'
          >
            {contentData?.load_more}
          </button>
        ) : null}
        {(count > defCount && count % defCount !== 0) || showLess ? (
          <button
            onClick={this.showLessBtn}
            className=' p-[7px_15px] text-[#F05127] text-[16px] border-[2px] border-[#F05127] font-[700] rounded-[50px]'
          >
            {contentData?.show_less}
          </button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
});

export default connect(mapStateToProps)(LoadMoreBtn);
