import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Card11 from './cards/Card11'

import bina from '../images/bina.png'
import bag from '../images/bag.png'
import location from '../images/location.png'
import phone from '../images/phone.png'
import services from '../images/services.png'
import { connect } from 'react-redux'


export class MainAboutUs extends Component {
  render() {
    const { contentData } = this.props;
    return (
      <div className=' grid grid-cols-2 gap-[32px] max-[900px]:grid-cols-1'>

        <div className='p-[20px] flex flex-col bg-[#fff] rounded-[30px] max-[500px]:p-[14px]'>
          <img src={bina} className='w-full h-full rounded-[30px]' />

          <div className='mt-[16px]'>
            <p className='font-[400] text-[22px] text-[#000] max-[500px]:text-[18px]'>
              {contentData?.location_info}
            </p>
          </div>

        </div>

        <div className='grid grid-cols-2 gap-[28px]  max-[500px]:grid-cols-1'>

          <Card11
            image={bag}
            title={contentData?.professionalism}
            text={contentData?.professionalism_info_txt}
          />

          <Card11
            image={location}
            title={contentData?.location}
            text={contentData?.info_location}
          />

          <Card11
            image={phone}
            title={contentData?.contact}
            text="+994 12 441 36 38"
            text1="+994 55 223 01 38"
          />

          <Card11
            image={services}
            title={contentData?.support_txt}
            text="+994 55 223 01 23"
          />

        </div>

      </div>
    )
  }
}

const mapDispatchToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
})

export default connect(mapDispatchToProps)(MainAboutUs)