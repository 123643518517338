import React, { Component } from 'react'
import ProductList from './ProductList'
import { getProductCategories } from '../actions/MainAction'
import { connect } from 'react-redux'
import CloseBtn from './svg/CloseBtn'

export class ProductListSection extends Component {

  componentDidMount() {
    this.props.getProductCategories(this.props.lang_id)
  }

  render() {
    const { productListData, closeMenu, contentData } = this.props;

    return (
      <section className=' max-[1024px]:bg-[var(--body)] bg-[#fff] rounded-[8px] max-[1024px]:rounded-[0px]'>
        <div className='hidden p-[24px_40px] max-[1024px]:flex max-[1024px]:justify-between '>

          <div className=''>
            <h2 className='font-[500] text-[18px] text-[#272727]'>{contentData?.catalog}</h2>
          </div>
          <button onClick={closeMenu} className='mr-[20px]'><CloseBtn /></button>
        </div>

        <div className=' flex flex-col  gap-[20px] m-[0_15px_0_0] rounded-[8px] w-[320px] max-[1024px]:w-full p-[24px_40px] min-h-[100vh]'>
          {
            productListData?.map((data, i) => (
              <ProductList
                key={i}
                cotogories={data?.translate?.title}
                sub_cotgories={data?.sub_categories}
                type="product"
              />
            ))
          }
        </div>
      </section>
    )
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  productListData: state.Data.productListData,
  contentData: state.Data.contentData,
})

const mapDispatchToProps = { getProductCategories }
export default connect(mapStateProps, mapDispatchToProps)(ProductListSection)