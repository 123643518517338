import React, { Component } from 'react'
import { connect } from 'react-redux';


export class Card12 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTurnedOff: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState((prevState) => ({
      isTurnedOff: !prevState.isTurnedOff
    }));
  }
  render() {

    const { isTurnedOff } = this.state;
    const { turn_off, turn_on, contentData } = this.props;
    return (
      <div className="border-[1px] rounded-[50px] border-[#1B1B29] m-[0px_20px] flex items-center justify-between gap-[50px] p-[0px_25px] max-[900px]:gap-[20px] max-[900px]:flex-col max-[900px]:p-[20px] max-[370px]:gap-[10px]">
        <div>
          <h2 className="font-[700] text-[85px] max-[1180px]:text-[65px] max-[1080px]:text-[55px] max-[980px]:text-[45px] max-[420px]:text-[35px] max-[370px]:text-[25px]">Şərab Soyuducu</h2>
        </div>

        <img
          src={isTurnedOff ? turn_off : turn_on}
          id="soyuducu-img"
          className="max-w-[310px] max-[380px]:w-[270px] max-[330px]:w-[250px]"
          alt="Soyuducu" />

        <div className="flex flex-col w-full items-start max-[900px]:items-center">
          <span className="font-[400] text-[16px] leading-[20px] text-[#0A0C10] max-[370px]:text-[12px]">38x39x190</span>
          <p className="font-[500] text-[45px] text-[#1F1E1E] max-[1080px]:text-[35px] max-[930px]:text-[25px] max-[370px]:text-[17px]">{contentData?.slider_cool}</p>
          <p className="font-[400] text-[14px] text-start max-w-[60%] leading-[21px] text-[#1F1E1E] max-[930px]:text-[12px] max-[900px]:text-center max-[390px]:w-full">
            {contentData?.slider_info_card}
          </p>

          <div className="flex items-center gap-[20px] mt-[15px] max-[1000px]:gap-[10px]">
            <button className="font-[700] text-[16px] text-[#fff] bg-[#000] border-[1px] border-[#000] p-[5px_20px] rounded-[50px] max-[1000px]:p-[5px_14px] max-[1000px]:text-[14px] max-[370px]:text-[12px] max-[370px]:font-[500]">
              {contentData?.sign_in}
            </button>
            <button id="turn_off" className="font-[700] text-[16px] w-[100px] text-[#000]  p-[5px_20px] max-[1000px]:p-[5px_14px] rounded-[50px] max-[1000px]:text-[14px] max-[370px]:text-[12px] max-[370px]:font-[500] " onClick={this.handleToggle}>
              {isTurnedOff ? contentData?.turn_on : contentData?.turn_off}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id

})

export default connect(mapStateToProps)(Card12)